import { LoginCallback } from '@okta/okta-react';
import { PageLoader } from '@optools/legacy-ui/components/loading/PageLoader';
import { useAccessCheck } from '@optools/legacy-ui/hooks/useAccessCheck';
import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes as ReactRouterRoutes } from 'react-router-dom';

import Home from './Home';
import { MUIProviders } from './mui-providers';
import SecureOutlet from './SecureOutlet';

const AuditRoutes = lazy(() => import('@optools/tools/audit/audit-routes'));
const BTCToolsRoutes = lazy(() => import('@optools/tools/btc-tools/BTCToolsRoutes'));
const ESNIRoutes = lazy(() => import('@optools/tools/esni/esni-routes'));
const ExperienceOverrideRoutes = lazy(
  () => import('@optools/tools/experience-override/ExperienceOverrideRoutes'),
);
const IPOverrideRoutes = lazy(() => import('@optools/tools/ip-override/ip-override-routes'));
const LoggerRoutes = lazy(() => import('@optools/tools/logger/LoggerRoutes'));
const MilestonesRoutes = lazy(() => import('@optools/tools/milestones/MilestonesRoutes'));
const MediaValidationRoutes = lazy(
  () => import('@optools/tools/media-validation/media-validation-routes'),
);
const PartnerRoutes = lazy(() => import('@optools/tools/partner/PartnerRoutes'));
const PageNotFound = lazy(() => import('./page-not-found'));
const Red5Poc = lazy(() => import('../../Red5Poc/Red5Poc'));

export default function Routes() {
  const {
    showAudit,
    showBtcTools,
    showEsni,
    showExpOverride,
    showIpOverride,
    showLogger,
    showMediaValidation,
    showMilestones,
    showPartnerStreamDelivery,
    showRed5,
  } = useAccessCheck();

  return (
    <Suspense fallback={<PageLoader withDelay />}>
      <ReactRouterRoutes>
        {/* TODO: Remove MUI Providers wrapper route when Home has been refactored */}
        <Route element={<MUIProviders />}>
          <Route element={<Home />} path="/" />
        </Route>

        <Route element={<PageNotFound />} path="*" />
        <Route element={<LoginCallback />} path="/implicit/callback" />
        <Route element={<Navigate to="/" />} path="/login" />
        <Route element={<SecureOutlet />} path="/secure/*">
          {showBtcTools && <Route element={<BTCToolsRoutes />} path="btc-tools/*" />}

          {showEsni && <Route element={<ESNIRoutes />} path="esni/*" />}

          {showMediaValidation && (
            <Route element={<MediaValidationRoutes />} path="media-validation/*" />
          )}

          {showAudit && <Route element={<AuditRoutes />} path="audit/*" />}

          {/* TODO: As tools are migrated, move them out of the MUIProviders route to the parent "secure" route */}
          <Route element={<MUIProviders />}>
            {showExpOverride && (
              <Route element={<ExperienceOverrideRoutes />} path="experience-override/*" />
            )}

            {showIpOverride && <Route element={<IPOverrideRoutes />} path="ip-override/*" />}

            {showLogger && <Route element={<LoggerRoutes />} path="logger/*" />}

            {showMilestones && <Route element={<MilestonesRoutes />} path="milestones/*" />}

            {showPartnerStreamDelivery && <Route element={<PartnerRoutes />} path="partner/*" />}

            {showRed5 && <Route element={<Red5Poc />} path="red5/*" />}
          </Route>
        </Route>
      </ReactRouterRoutes>
    </Suspense>
  );
}
